import React from 'react'
import Slider from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import { FooterContainer, FooterInnerContainer } from '../../styles/Bars';
import { NextButton, BackButton } from '../../styles/Buttons';
import { Link, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer(props) {
  return (
    <FooterContainer>
        <BackButton onClick={props.BackButton}>
            Back
        </BackButton>
        <FooterInnerContainer>
        <YouTubeIcon style={{ width: "45px", height: "40px" }} />
        <Typography style={{ paddingLeft: "12px" }} color="white">Need Help?
          <Link style={{ paddingLeft: "6px" }} href="#" color="inherit" >
            Watch our how to video guide
          </Link>
        </Typography>

        </FooterInnerContainer>
        <NextButton onClick={props.NextButton}>Next</NextButton>
    </FooterContainer>
  )
}

export default Footer