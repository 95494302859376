import {styled} from "@mui/material/styles";
import { Box } from '@mui/material';
import theme from "./Theme";
import { border } from "@mui/system";


export const HeaderContainer = styled(Box)(() =>({
    padding: 0,
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 2
}));

export const LogoContainer = styled(Box)(() =>({
    display: "flex",
    backgroundColor: theme.palette.common.black,
    height: theme.headerHeight,
    zIndex: 4,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    paddingLeft: theme.paddingSides,
    paddingRight: theme.paddingSides
}));

export const OuterContainer = styled(Box)(() =>({
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    height: theme.middleHeaderHeight,
    paddingLeft: theme.paddingSides,
    paddingRight: theme.paddingSides,
    alignItems: "center",
    justifyContent: "space-between",
    // border: '1px solid white'
}));

export const InnerContainer = styled(Box)(() =>({
    display: "flex",
    width: 1000,
    // border: '1px solid white'
}));

export const SideItem = styled(Box)(() =>({
    width: 300,
    // border: '1px solid white'
}));

export const FooterContainer = styled(Box)(() =>({
    display: "flex",
    backgroundColor: theme.palette.primary.dark,
    paddingRight: theme.paddingSides,
    paddingLeft: theme.paddingSides,
    height: theme.footerHeight,
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    bottom: "0",
    width: "100%"
}));

export const FooterInnerContainer = styled(Box)(() =>({
    display: "flex",
    color: theme.palette.common.white,
    alignItems: "center"
}));










