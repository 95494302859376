import React from 'react'
import { HeaderContainer, LogoContainer } from '../../styles/Bars'
import MicrosoftLogo from "../../../src/assets/images/HeaderLogo.png"
import CommandBar from './CommandBar';
import ModuleMenu from '../ModuleMenu/ModuleMenu';
import { ClickAwayListener } from '@mui/material';



function Header(props) {
    return (
        <>
            <HeaderContainer>
                <LogoContainer>
                    <img style={{ maxWidth: 300 }} src={MicrosoftLogo} alt="Microsoft Logo" />
                </LogoContainer>

                <CommandBar toggleDrawer={props.toggleDrawer} isDrawerOpen={props.isDrawerOpen}></CommandBar>
                <ModuleMenu toggleDrawer={props.toggleDrawer} index={props.index} isDrawerOpen={props.isDrawerOpen} UNITY_CONTEXT={props.UNITY_CONTEXT} />

            </HeaderContainer>
        </>
    )
}

export default Header;