import { Box, Button, ClickAwayListener, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InnerContainer, OuterContainer, SideItem } from '../../styles/Bars'
import { NextButton } from '../../styles/Buttons';
import MenuIcon from '@mui/icons-material/Menu';
import theme from '../../styles/Theme';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import MenuIcon from '@mui/icons-material/Menu';




function CommandBar(props) {

    const [progress, setProgress] = useState(100);

    useEffect(() => {

        setProgress(100);
    })


    return (
        <div>
            <OuterContainer>
                <SideItem>


                    <Button variant="text" size='large'
                        sx={{
                            color: theme.palette.common.white,
                            // bgcolor: 'red'
                        }}
                        onClick={props.toggleDrawer}
                        startIcon={props.isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                    >
                        {props.isDrawerOpen ? 'Close Menu' : 'Open Menu'}
                    </Button>

                </SideItem>
            </OuterContainer>
        </div>
    )
}

export default CommandBar