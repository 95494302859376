import { Button} from "@mui/material";
import {styled} from "@mui/material/styles";
import theme from "./Theme";

export const NextButton = styled(Button)(() =>({
    ':hover': {
        backgroundColor: 'white',
    },
    color: theme.palette.common.black,
    borderColor: theme.palette.common.white,
    width: 148,
    height: 33,
    marginRight: 50,
    borderRadius: "5.5px",
    border: "solid 2.1px #fff",
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 12
}));

export const BackButton = styled(Button)(() =>({
    ':hover': {
        backgroundColor: 'black',
    },
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    width: 148,
    height: 33,
    borderRadius: "5.5px",
    border: "solid 2.1px #fff",
    fontWeight: "bold",
    fontSize: 12
}));

export const MenuButton = styled(Button)(() =>({
    ':hover': {
        backgroundColor: 'white',
    },
    color: theme.palette.common.black,
    borderColor: theme.palette.common.white,
    width: '100%',
    height: '100%',
    // marginRight: 50,
    borderRadius: "5.5px",
    border: "solid 2.1px #fff",
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 12
}));

