import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import theme from './styles/Theme';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import React, { Fragment, useEffect, useState } from 'react';
import XRContainer from './components/XRContainer/XRContainer';
import { UnityContext } from "react-unity-webgl";
import Loader from './components/Loader/Loader';
import VideoCanvas from './components/VideoCanvas/VideoCanvas';
import { Button } from '@mui/material';



const UNITY_CONTEXT = new UnityContext({
  loaderUrl: "unity/Build/unity.loader.js",
  dataUrl: "unity/Build/unity.data.unityweb",
  frameworkUrl: "unity/Build/unity.framework.js.unityweb",
  codeUrl: "unity/Build/unity.wasm.unityweb"
})

function App() {

  const [isLoaded, setIsLoaded] = useState(false);

  const [XRReady, setXRReady] = useState(false);
  const [progression, setProgression] = useState(25);
  const [started, setStarted] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [index, setIndex] = useState(0);

  const NextButton = () => {
    console.log("NextButton");
    UNITY_CONTEXT.send("Manager", "NextButton")
  }

  const BackButton = () => {
    console.log("BackButton");
    UNITY_CONTEXT.send("Manager", "BackButton")
  }


  useEffect(function () {

    UNITY_CONTEXT.on("loaded", () => {
      setIsLoaded(true);
      console.log("Unity Loaded");
    });
    
    UNITY_CONTEXT.on("OnXRReady", () => {
      setXRReady(true);
      console.log("XR Ready");
    });


    UNITY_CONTEXT.on("PlayReactVideo", () => {
      handleOpen();

    })
    
    UNITY_CONTEXT.on("progress", function (progression) {
      setProgression(progression);
      console.log(progression);
    });

    UNITY_CONTEXT.on("SceneNotification", function (currentScene) {
      console.log(currentScene);
      let temp;
      temp = parseInt(currentScene);
      setIndex(temp);
      // setCurrentSceneIndex(index);
      console.log(`CurrentSceneINdex is : ${index}`);
    });

  }, []);


  function startExperince() {
    console.log("Started");
    setStarted(true);
    UNITY_CONTEXT.send("Manager", "SceneLoader", 1);
  }


  function toggleDrawer() {
    console.log("Toggle Drawer Clicked");
    if(isDrawerOpen)
    {

      setIsDrawerOpen(false);
    }else{
      setIsDrawerOpen(true);
    }
    console.log(`Drawer open value is: ${isDrawerOpen}`);
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {
          !started && 
          <Loader progression={progression} onClickHandler={startExperince} XRReady={XRReady}/>
        }
        {
          started && 
          <React.Fragment>
            < Header toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} index={index} UNITY_CONTEXT={UNITY_CONTEXT} />
            <Footer NextButton={NextButton} BackButton={BackButton} />
          </React.Fragment>
        }
        <XRContainer UnityContext={UNITY_CONTEXT} display={started} />
        {/* <Button onClick={handleOpen} style={{marginBottom: 500}}>Test Button</Button> */}
        <VideoCanvas handleClose={handleClose} handleOpen={handleOpen} open={open} index={index}/>
      </React.Fragment>
    </ThemeProvider >
  );
}

export default App;
