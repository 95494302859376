// import { CircularProgress, makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { fontWeight, height } from "@mui/system";
import React from "react";
import theme from '../../styles/Theme';




// const useStyles = makeStyles((theme) => ({
//   loaderContainer: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     height: "66vh",
//     zIndex: 3,
//     paddingTop: 186,
//     backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)",
//     overflow: "hidden"

//   },
//   loadingBox: {
//     marginTop: 30,
//     position: "relative",
//     display: "flex",


//   },
//   loadingCircleProgress: {
//     margin: "auto",
//     fill: theme.palette.secondary.main,


//   },
//   loadingNumber: {
//     color: theme.palette.secondary.main,
//     fontWeight: "bold",
//     fontSize: "3rem",
//     position: "absolute",
//     top: "25%",
//     left: "0%",
//     bottom: "45%",
//     right: "0%",
//     textAlign: "center",
//     margin: "auto"
//   },
//   text: {
//     color: "white",
//     fontWeight: "lighter",
//     fontSize: "30px",
//     marginTop: "100px",
//   },
//   span: {
//     color: theme.palette.primary.main,
//   },
//   startButton: {
//     backgroundColor: "#FF1A68",
//     color: "#ffff",
//     width: 190,
//     height: 45,
//     marginTop: 52,
//     marginBottom: 10,
//     marginRight: "auto",
//     marginLeft: "auto",
//     fontSize: 17,
//     fontWeight: "bold",
//     borderRadius: 40,
//     border: '0',
//     cursor: 'pointer'

//   }
// }
// ));

function Loader(props){
  // const classes = useStyles();
  // const { t } = useTranslation("ui");

  return (
    <div className='loaderContainer' style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "80vh",
      zIndex: 3,
      paddingTop: 186,
      backgroundColor: '#1D1E20',
      overflow: "hidden"
    }}>
      <div className='loadingBox' style={{
        marginTop: 90,
        position: "relative",
        display: "flex",
      }}>
        <CircularProgress
          color="secondary"
          variant="determinate"
          value={props.progression *100}
          size={150}
          thickness={1.7}
          className='loadingCircleProgress'
          style={{
            margin: "auto",
            color: '#239AD1',
          }}
        />
        <p className='loadingNumber' style={{
          color: 'white',
          fontWeight: "normal",
          fontSize: "3rem",
          position: "absolute",
          top: "25%",
          left: "0%",
          bottom: "45%",
          right: "0%",
          textAlign: "center",
          margin: "auto"

        }}>{(props.progression *100).toFixed(0) + "%"}</p>
      </div>
      {props.XRReady && <button className='startButton' style={{
          backgroundColor: "#239AD1",
          color: "#ffff",
          width: 190,
          height: 45,
          marginTop: 52,
          marginBottom: 10,
          marginRight: "auto",
          marginLeft: "auto",
          fontSize: 17,
          fontWeight: "bold",
          borderRadius: 5,
          border: '0',
          cursor: 'pointer'

      }} onClick={props.onClickHandler}>Get Started</button>}
    </div>
  );
};

export default Loader;
