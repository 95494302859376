import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: "#4a4e4e",
            main: "#2f2f2f",
            dark: "#000000",
            contrastText: "#fff"
        },
        secondary: {
            light: "#ff5578",
            main: "#e6007d",
            dark: "#00bbf4",
            contrastText: "#000"
        },
        text: {
            primary: "#ffffff"
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            }
        }
    },
    slideMenuWidth: 500,
    footerHeight: 50,
    sidebarWidth: 500,
    headerHeight: 80,
    middleHeaderHeight: 49,
    subHeaderHeight: 30,
    narratorSize: 235,
    paddingSides: 36,
})


export default theme;