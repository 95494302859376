import React, { useState, useEffect } from 'react';
import Unity from "react-unity-webgl";






function XRContainer(props) {

    return (
        <div>
            {
            <Unity
                style={{
                    display: props.display? 'block' : 'none',
                    height: "100vh",
                    width: "100vw",
                }} unityContext={props.UnityContext} /> 
            }

        </div>
    )
}

export default XRContainer