import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 720,
    height: 540,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
};

const urls = ['https://vimeo.com/736468514/afcd23decf', 'https://vimeo.com/736468468/47275fa1b5'];


export default function VideoCanvas(props) {


    const [currentVideoURL, setCurrentVideoURL] = React.useState(urls[0]);
    const [disableNext, setDisableNext] = React.useState(false);
    const [disablePrevious, setDisablePrevious] = React.useState(false);

    function OnClickNext() {
        setCurrentVideoURL(urls[1]);
        setDisableNext(true);
        setDisablePrevious(false);

    }


    function OnClickPrevious() {
        setCurrentVideoURL(urls[0]);
        setDisableNext(false);
        setDisablePrevious(true);

    }


    return (
        <div>
            {/* <Button onClick={props.handleOpen}>Open modal</Button> */}
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     width: 720,
                     height: 540,
                     bgcolor: 'background.paper',
                     border: '2px solid #000',
                     boxShadow: 24,
                     paddingBottom: 1,
                     paddingTop: 1,
                     paddingLeft: 2,
                     paddingRight: 2,
                     display: 'flex',
                     flexDirection: 'column'
                }}>
                    <ReactPlayer className="video" style={{ height: '100%', width: '100%', }} url={currentVideoURL} controls={true} width="100%" height="100%" playing={true} />
                    {!(props.index == 6) &&
                        <React.Fragment>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'space-around',
                            }}>
                                <Button variant="contained" sx={{bgcolor:'#239AD1'}} disabled={disablePrevious} style={{
                                   
                                }}
                                    onClick={OnClickPrevious}>
                                    Previous
                                </Button>
                                <Button variant="contained" sx={{bgcolor:'#239AD1'}} disabled={disableNext} style={{
                
                                }}
                                    onClick={OnClickNext}>
                                    Next
                                </Button>
                            </Box>

                        </React.Fragment>
                    }
                </Box>
            </Modal>
        </div>
    );
}
