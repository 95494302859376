import React, { useEffect, useState } from 'react'
import { Drawer, Box, Typography, Slide, ClickAwayListener } from '@mui/material'
import theme from '../../styles/Theme'
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { styled } from "@mui/material/styles";
import { color } from '@mui/system';



const StyledTreeItem = styled(TreeItem)(() => ({
  padding: 20,
  color: "rgba(255, 255, 255, 0.5)",
  cursor: 'pointer',
  "&:hover": {
    color: 'white',
    backgroundColor: "rgba(255, 255, 255, 0.1)"
  }

}));

const StyledTreeHeader = styled(TreeView)(() => ({
  padding: 20,
}));


function ModuleMenu(props) {

  const [currentSection, setCurrentSection] = useState(0);

  const items = [
    {
      id: 0,
      buildIndex: 1,
      SectionHeader: 'Welcome to Dream Space',
    },
    {
      id: 1,
      buildIndex: 2,
      SectionHeader: 'Dream Space Overview ',
    },
    {
      id: 2,
      buildIndex: 3,
      SectionHeader: 'Text-Based Programming and Web Design',
    },
    {
      id: 3,
      buildIndex: 4,
      SectionHeader: 'MakeCode',
    },
    {
      id: 4,
      buildIndex: 5,
      SectionHeader: 'Sphero EDU and Robotics LittleBits',
    },
    {
      id: 5,
      buildIndex: 6,
      SectionHeader: 'Minecraft: Education Edition',
    },
    {
      id: 6,
      buildIndex: 7,
      SectionHeader: 'Future Technologies',
    }

  ];

  useEffect(() => {

    console.log(currentSection);
    items.map((item) => {
      if (item.buildIndex == currentSection) {
        props.UNITY_CONTEXT.send("Manager", "SceneLoader", item.buildIndex);
      }

    })

  }, [currentSection]);


  useEffect(() => {
    setCurrentSection(props.index);
    console.log("Current Section changed from Unity", props.index);

  }, [props.index])

  return (
    <>

        <Slide in={props.isDrawerOpen} direction="right" mountOnEnter unmountOnExit>
          <div style={{
            position: "fixed",
            paddingTop: 26,
            width: 500,
            left: 0,
            top: theme.headerHeight + theme.middleHeaderHeight,
            bottom: theme.footerHeight,
            backgroundColor: "rgba(74, 78, 78, 0.8)"
          }}>

            <StyledTreeHeader>
              {items.map((item, index) => {
                return (
                  <StyledTreeItem
                    key={item.id}
                    nodeId={item.id.toString()}
                    label={item.SectionHeader}
                    onClick={() => setCurrentSection(item.buildIndex)}
                    sx={{ color: currentSection == item.buildIndex ? 'white' : 'rgba(255, 255, 255, 0.5)' }}
                  />
                )
              })}
            </StyledTreeHeader>
          </div>
        </Slide>

    </>
  )
}

export default ModuleMenu